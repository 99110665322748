<template lang="pug">
component(
  v-bind:is='overviewComponent', 
  :task-attribute='taskAttribute'
  :attributes='attributes'
)

</template>

<script>

  export default {
    components: {

    },
    props: {
      taskAttribute: Object,
      attributes: Object,
    },
    data () {
      return {
      }
    },
    watch: {

    },
    methods: {
      
    },
    computed: {
      overviewComponent () {
        const name = this.taskAttribute.AttributeType.Description.replaceAll(' ', '') + 'Overview.vue'
        return () => import('@/components/tasks/' + name)
      }
    },
    mounted () {
     
    }
  }
</script>

<style scoped>

</style>
