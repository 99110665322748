var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.overviewComponent, {
    tag: "component",
    attrs: { "task-attribute": _vm.taskAttribute, attributes: _vm.attributes }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }